<div class="main-content">  
    <div class="scrollable full-height-minus-header">
      <div class="container px-md-0">
        <h1 class="my-3">Final Online Assessments</h1>

        <div class="card">
          <div class="card-body">
            <div *ngIf="isIE()" role="alert" class="alert alert-danger"><i class="fa fa-lg fa-exclamation-triangle fa-fw"></i>Final Online Assessments are not supported by your browser. Please upgrade to the latest version of Chrome, Safari, Edge or Firefox. (You may be able to download the assessment paper, but will not be able to submit your answers. For further information please contact <a href="mailto:ebs.enquiries@hw.ac.uk">ebs.enquiries@hw.ac.uk</a>.)</div>
            
            <p>
                Prior to sitting any final online assessments, we strongly recommend that you complete the short final online assessment requirements and guidance course, that has been added to your course dashboard.<br/>
                Your upcoming final online assessments are listed below. Download your assessment papers and upload your answers here. <br/>
                You have 24 hours to submit your answers. You do not need to wait until the deadline to submit your answers and can submit at any point during the 24-hour time period. Please refer to the below before submitting: 
            </p>
            <ul>
              <li><a [href]="generalInfoURL" target="_blank">Student general information guide to final online assessments </a> (for the following programmes: DBA, Doctor of Philosophy, Pg Cert in Business Research Methods, MBA, MBA with Specialisms, MSc in Financial Management, MSc in Supply Chain Management and Logistics, MSc in Business Analytics, MSc in Business and Organisational Psychology)</li>
              <li><a [href]="generalInfoURLAlt" target="_blank">Student general information guide to final online assessments </a> (for the following programmes: MSc in Digital Leadership)</li>
              <li><a [href]="technicalGuideURL" target="_blank">Student technical guide to final online assessments </a></li>
              <li><a [href]="checkListURL" target="_blank">Student checklist: Before you submit your answers</a></li>
            </ul>
            <div role="alert" class="alert alert-warning"><i class="fa fa-lg fa-exclamation-triangle fa-fw"></i> All dates and times are UK time</div>
 
            <hw-spinner class="px-3 py-1" [spinnerMessage]="'loading exams..'" [faColour]="'#8031A7'"
            [boxColour]="'#8031A7'" *ngIf="isExamListLoading" [boxPadding]="'5px 15px'"></hw-spinner>
            <h2 class="mt-3">Upcoming Assessments</h2>
            <div *ngIf="isExamListLoading === false" class="downloads-available-wrapper">
              <div *ngIf="!hasUpcomingExams()" role="alert" class="alert alert-info"><i class="fa fa-lg fa-exclamation-triangle fa-fw"></i> You have no upcoming final online assessments</div>
              <hw-exam-list-item *ngFor="let exam of exams" [exam]="exam"></hw-exam-list-item>
              <div *ngIf="hasPracticeExams()">
                <h2 class="mt-3">Practice Final Online Assessments</h2>
                <p>
                  This is a sample assessment, available to all students, to allow you to see how the final online assessments
                  work.
                  You can download a sample assessment paper using the 'Download assessment paper' button, and upload a
                  document to the answers area. Please note that no one will read your answers, and uploaded documents will be deleted
                  periodically.
                </p>
                <hw-exam-list-item *ngFor="let exam of practiceExams" [exam]="exam"></hw-exam-list-item>
              </div>
            </div>  
          </div>
        </div>      
      </div>
    </div>
  </div>

import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationHelperService {

    constructor() {
    }

    public getApiServerUri(): string {
        return AppConfigService.settings.apiServer.prefix;
    }

    public getPollingInterval(): number {
        return AppConfigService.settings.polling.interval;
    }

    public getHeaderLinks(): any {
        return AppConfigService.settings.headerLinks;
    }

    public getPasswordResetUri(): string {
        return AppConfigService.settings.accountServices.passwordResetUri;
    }

    public getTermsAndConditionsAgreementUri(): string {
        return AppConfigService.settings.accountServices.termsAndConditionsAgreementUri;
    }

    public getForgottenUsernameUri(): string {
        return AppConfigService.settings.accountServices.forgottenUsernameUri;
    }

	public getForgottenPasswordUri(): string {
	  return AppConfigService.settings.accountServices.forgottenPasswordUri;
    }

    public getOnePortalLoginPageUrl(): string{
        return AppConfigService.settings.onePortal.loginPageUrl;
    }

    public getOnePortalDashboardUrl(): string{
        return AppConfigService.settings.onePortal.dashboardUrl;
    }

    public getTakeAtHomeChecklist(): string{
        return AppConfigService.settings.takeAtHomeInfo.checkList;
    }

    public getTakeAtHomeTechnicalGuide(): string{
        return AppConfigService.settings.takeAtHomeInfo.technicalGuide;
    }

    public getTakeAtHomeGeneralInfo(): string{
        return AppConfigService.settings.takeAtHomeInfo.generalInfo;
    }
    
    public getTakeAtHomeGeneralInfoAlt(): string{
        return AppConfigService.settings.takeAtHomeInfo.generalInfoAlt;
    }
}
<div class="main-content">
    <div class="scrollable full-height-minus-header">
        <div class="container px-md-0">
            <h1 class="my-3">Final Online Assessment timetable entries</h1>
            <hw-spinner class="px-3 py-1" [spinnerMessage]="'loading timetable entries..'" [faColour]="'#8031A7'"
                [boxColour]="'#8031A7'" *ngIf="isTimetableListLoading" [boxPadding]="'5px 15px'"></hw-spinner>
            <div class="card">
                <div class="card-body" *ngIf="!isTimetableListLoading && userIsUnauthorized">
                <div role="alert" class="alert alert-danger"><i class="fa fa-lg fa-exclamation-triangle fa-fw"></i> Sorry - you do not have access to this page</div>
                </div>
                <div class="card-body" *ngIf="!isTimetableListLoading && !userIsUnauthorized">
                    
                    <hw-lti-settings></hw-lti-settings>
                    <div class="row">
                        <div class="col-md-3">
                            <label for="session">Session</label>
                            <select (change)="filterBySession($event.target.value)" class="form-control" id="session" >
                                <option *ngFor="let sessioni of sessions" [value]="sessioni" [selected]="sessioni===session">{{sessioni}}</option>
                            </select>
                        </div>
                    </div>
                    <p></p>
                    <div class="panel panel-sky">
                        <div class="panel-heading">
                            Exam Timetable Entries
                        </div>
                        <div class="table-responsive" *ngIf="isTimetableListLoading == false"
                            style="overflow-x: visible">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Course code</th>
                                        <th>Course name</th>
                                        <th>Language</th>
                                        <th>Go to Turnitin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let entry of filteredTimetableEntries">
                                        <td>{{entry.courseCode}}</td>
                                        <td>{{entry.courseName}}</td>
                                        <td>{{entry.languageName}}</td>
                                        <td>
                                            <div class="btn btn-primary wrapButtonText ng-tns-c9-3 ng-star-inserted"
                                                (click)="goToTurnitin(entry)"> Go to Turnitin</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

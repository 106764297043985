import { Injectable, Inject } from '@angular/core';
import { ServiceBase } from '../../core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '../models/operation-response';
import { TopMenu } from '../models/top-menu';


@Injectable({
  providedIn: 'root'
})
export class TopMenuService extends ServiceBase {
  private _topMenuUri: string;

  get topMenuUri(): string {
    this._topMenuUri = 'personalisation/topmenu';
    return this._topMenuUri;
  }
  set topMenuUri(theUri: string) {
    this._topMenuUri = theUri;
  }

  public getTopMenu(): Observable<OperationResponse<TopMenu>> {
    
    return new Observable<OperationResponse<TopMenu>>(subscriber => {
      this.getObjectFromRemoteStore(this.topMenuUri, subscriber);
    });
  }
}

import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UserService } from './user.service';
import { Subscription } from 'rxjs';
import { ConfigurationHelperService } from '../app-configuration/configuration-helper.service';
import { ApplicationEventDispatcher } from '../services/application-event-dispatcher.service';
import { ApplicationEventType } from '../models/application-event-type';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {

    constructor(
        private router: Router,
        public applicationEventDispatcher: ApplicationEventDispatcher,
        private configurationHelperService: ConfigurationHelperService,
        @Inject(UserService) private userService: UserService,
        @Inject(DOCUMENT) private document: Document
    ) {
       
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        await this.userService.getCurrentUserAwaitable();

        if (this.userService.currentUser  && (false ||
            (this.userService.currentUser.authenticationDetails
            && this.userService.currentUser.authenticationDetails.isLoggedOut === false))) {
                if (this.userService.currentUser.userAccount.shouldResetPassword) {
                    this.document.location.href = this.configurationHelperService.getPasswordResetUri();
                }
                if (this.userService.currentUser.userAccount.shouldAgreeToTermsAndConditions) {
                    this.document.location.href = this.configurationHelperService.getTermsAndConditionsAgreementUri();
                }
                return true;
        }

        this.applicationEventDispatcher
            .processCommonApplicationEvent('It looks as if your session has timed out. Please log in again.',
                ApplicationEventType.AuthenticationRequired);

        return false;
    }

    ngOnDestroy(): void {
    }
}

import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { TurnitinRequest } from '@app/models/turnitin-request';
import { Subscription } from 'rxjs';
import { TurnitinStateService } from '@app/services/turnitin-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TurnitinLtiService } from '@app/services/turnitin-lti.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { ApplicationEventDispatcher } from '@app/common/services/application-event-dispatcher.service';
import { ApplicationEventType } from '@app/common/models/application-event-type';
import { ExaminationEntry } from '@app/models/examination-entry';

@Component({
  selector: 'hw-turnitin-form',
  templateUrl: './turnitin-form.component.html',
  styleUrls: ['./turnitin-form.component.scss']
})
export class TurnitinFormComponent implements OnInit, OnDestroy {

  isValidPageRequest: boolean;
  isValidTurnitinRequest: boolean;

  isLoading: boolean;
  isLoadingTurnitingRequst: boolean;
  courseCode: string;
  languageCode: string;
  sessionCode: string;

  ltiRequest: TurnitinRequest;
  ltiBadRequestMessage: string;

  queryStringSubscription: Subscription;
  routeValuesSubscription: Subscription;

  @ViewChild("TurnitinForm") turnitinForm;

  constructor(private route: ActivatedRoute,
              private turnitinLtiService: TurnitinLtiService,
              private changeDetectorRef: ChangeDetectorRef,
              private applicationEventDispatcher: ApplicationEventDispatcher) { }

  ngOnInit(): void {
    this.applicationEventDispatcher.processCommonApplicationEvent('', ApplicationEventType.HideHeader);

    this.checkIfPageIsStillLoading();

    this.routeValuesSubscription = this.route.params.subscribe(params => {
        this.sessionCode = params.sessionCode;
        this.courseCode = params.courseCode;
        this.languageCode = params.isoCode;

        this.checkIfPageIsStillLoading();
      });
  }

  checkIfPageIsStillLoading(): void{
    let areAllNeededValuesPresent = false;

    if (this.sessionCode != null &&
      this.languageCode != null &&
      this.courseCode != null){
        areAllNeededValuesPresent = true;
        this.checkValidityOfPageRequest();
      }

    this.isLoading = !areAllNeededValuesPresent;
  }

  checkValidityOfPageRequest(): void{
        this.isValidPageRequest = true;
        this.getLtiRequest();
   }

  getLtiRequest(): void{
    const examinationEntry = new ExaminationEntry();
    examinationEntry.courseCode = this.courseCode;
    examinationEntry.languageCode = this.languageCode;
    examinationEntry.sessionCode = this.sessionCode;

    this.isLoadingTurnitingRequst = true;
    this.turnitinLtiService
    .getTurnitinRequest(examinationEntry)
    .subscribe(
      x => {
        this.isLoadingTurnitingRequst = false;
        this.isValidTurnitinRequest = true;
        this.ltiRequest = x.data;

        this.submitLtitForm();
      },
      error => {
        const errorOperationResponse = error as OperationResponse<TurnitinRequest>;
        this.isValidTurnitinRequest = false;
        this.isLoadingTurnitingRequst = false;

        if (errorOperationResponse != null && errorOperationResponse.notifications != null
          && errorOperationResponse.notifications.length > 0){
          this.ltiBadRequestMessage = errorOperationResponse.notifications[0].message;
        } else {
          this.ltiBadRequestMessage = 'Cannot access turnitin at this time';
        }
      }
    );
  }

  public submitLtitForm(): void {
    this.changeDetectorRef.detectChanges();
    this.turnitinForm.nativeElement.submit();
  }

  ngOnDestroy(): void {
    if (this.queryStringSubscription != null) {
      this.queryStringSubscription.unsubscribe();
    }
    if (this.routeValuesSubscription != null){
      this.routeValuesSubscription.unsubscribe();
    }
  }
}

import { Injectable, ApplicationRef} from '@angular/core';
import { UserService } from '../common/security/user.service';
import { StateService } from './state.service';
import { ConfigurationHelperService } from '../common/app-configuration/configuration-helper.service';
import { ApplicationEventDispatcher } from '@app/common/services/application-event-dispatcher.service';
import { ApplicationEventType } from '@app/common/models/application-event-type';

// M.S. for G.M. - we'd need to write some unit tests for this service (for each method)
@Injectable({
    providedIn: 'root'
})
export class PollingService {
    pollingInterval = 5000;
    isOffline = false;

    constructor(
        private applicationEventDispatcher: ApplicationEventDispatcher,
        private userService: UserService,
        private stateService: StateService,
        private configurationHelperService: ConfigurationHelperService,
        private applicationRef: ApplicationRef
        //@Inject(DOCUMENT) private document: Document
    ) {
        this.pollingInterval = this.configurationHelperService.getPollingInterval();
        this.isOffline = false;
    }

    pollTheServer(): number {
        return this.startStop();
    }

    startStop(): number {
        const root = this;
        clearInterval(this.stateService.intervalId);

        this.applicationRef.isStable.subscribe((isStable) => {
            if (isStable) {
                this.stateService.intervalId = window.setInterval(function () {
                    if (!root.userService.currentUserIsAuthenticatedOnRemoteServer()) {
                        //this.document.location.href = this.configurationHelperService.getOnePortalLoginPageUrl();
                        root.applicationEventDispatcher.processCommonApplicationEvent('It looks as if your session has timed out. Please log in again.', ApplicationEventType.AuthenticationRequired);
                        return 405;
                    } else {
                        // Fire event to signal everything is OK.
                        //root.applicationEventDispatcher.processHttpResponseStatus(200);
                    }
                    return 200;
                }, this.pollingInterval);
            }
          });

        return 200;
    }
}

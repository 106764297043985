import { BaseObject } from '@app/core/storable-object';

export class ExaminationEntry extends BaseObject {
    courseName: string;
    languageName: string;
    openingDateTime: Date;
    closingDateTime: Date;
    sessionCode: string;
    languageCode: string;
    isPracticeExamination: boolean;
    hasOpen: boolean;
    isClosed: boolean;
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExaminationEntry } from '@app/models/examination-entry';
import { OperationResponse } from '@app/common/models/operation-response';
import { ServiceBase } from '@app/core/service-base';
import { TurnitinRequest } from '@app/models/turnitin-request';
import { LtiSettings } from '@app/models/lti-settings';


@Injectable({
    providedIn: 'root'
})
export class TurnitinLtiService {

    constructor(private serviceBase: ServiceBase){

    }

    getTurnitinRequest(examinationEntry: ExaminationEntry): Observable<OperationResponse<TurnitinRequest>>{
        const turnitinRequestUrl = this.getTurnitinRequestUrl(examinationEntry);
        return this.serviceBase.getObject<TurnitinRequest>(turnitinRequestUrl);
    }

    getTurnitinRequestUrl(examinationEntry: ExaminationEntry): string {
        if (examinationEntry == null) { return ''; }
        const turnitinLtiUrl = `lti/request/${examinationEntry.sessionCode}/${examinationEntry.courseCode}/${examinationEntry.languageCode}`;
        return turnitinLtiUrl;
    }

    getLtiStatus(): Observable<OperationResponse<LtiSettings>> {
        const ltiStatusUri = this.getLtiStatusUri();
        return this.serviceBase.getObject<LtiSettings>(ltiStatusUri);
    }

    setLtiStatus(ltiSettings: LtiSettings): Observable<OperationResponse<LtiSettings>> {
        const ltiStatusUri = this.getLtiStatusUri();
        return this.serviceBase.setObject<LtiSettings>(ltiSettings, ltiStatusUri);
    }

    getLtiStatusUri(): string{
        return 'lti/turnitin/status';
    }
}

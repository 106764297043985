import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExaminationEntry } from '@app/models/examination-entry';
import {TurnitinStateService } from '@app/services/turnitin-state.service';

@Component({
  selector: 'hw-ownership-statement',
  templateUrl: './ownership-statement.component.html',
  styleUrls: ['./ownership-statement.component.scss']
})
export class OwnershipStatementComponent implements OnInit, OnDestroy {
  courseName: string;
  courseCode: string;
  languageCode: string;
  sessionCode: string;

  hasAccepted: boolean;
  isLoading: boolean;
  consentRequiredMessageTriggered: boolean;

  generalInfoURL: string;
  technicalGuideURL: string;
  checkListURL: string;

  @Output()
  popupActions: EventEmitter<string>;

  queryStringSubscription: Subscription;
  routeValuesSubscription: Subscription;

  constructor(private configHelper: ConfigurationHelperService,
              private router: Router,
              private route: ActivatedRoute,
              private turnitinStateService: TurnitinStateService) {
    this.popupActions = new EventEmitter<string>();
  }
  ngOnDestroy(): void {
    if (this.queryStringSubscription != null) {
      this.queryStringSubscription.unsubscribe();
    }
    if (this.routeValuesSubscription != null){
      this.routeValuesSubscription.unsubscribe();
    }

  }

  ngOnInit(): void {
    this.generalInfoURL = this.configHelper.getTakeAtHomeGeneralInfo();
    this.technicalGuideURL = this.configHelper.getTakeAtHomeTechnicalGuide();
    this.checkListURL = this.configHelper.getTakeAtHomeChecklist();

    this.checkIfPageIsStillLoading();

    this.queryStringSubscription = this.route.queryParams
      .subscribe(params => {

        if (params != null){
          this.courseName = params.courseName;
          this.checkIfPageIsStillLoading();
        }
      });

    this.routeValuesSubscription = this.route.params.subscribe(params => {
        this.sessionCode = params.sessionCode;
        this.courseCode = params.courseCode;
        this.languageCode = params.isoCode;

        this.checkIfPageIsStillLoading();
      });
  }

  checkIfPageIsStillLoading(): void{
    let areAllNeededValuesPresent = false;

    if (this.sessionCode != null &&
      this.languageCode != null &&
      this.courseCode != null &&
      this.courseName != null){
        areAllNeededValuesPresent = true;
      }

    this.isLoading = !areAllNeededValuesPresent;
  }

  goToTurnitin(): void{
    if (!this.hasAccepted) {
      this.consentRequiredMessageTriggered = true;
      return;
    }
    let examinationEntry = new ExaminationEntry();
    examinationEntry.courseCode = this.courseCode;
    examinationEntry.courseName = this.courseName;
    examinationEntry.languageCode = this.languageCode;
    examinationEntry.sessionCode = this.sessionCode;

    this.turnitinStateService.setHasAgreedToOwnershipStatement(examinationEntry);
    this.router.navigate(['/turnitin', this.sessionCode, this.courseCode, this.languageCode],
    {queryParams: {courseName: this.courseName}});
  }

  closeConsent(): void{
    this.router.navigate(['/exams']);
  }
}

<div class="main-content">  
    <div class="scrollable full-height-minus-header">
      <div class="container px-md-0">

        <h1 *ngIf="isLoading" class="my-3">Submit your answers</h1>
        <h1 *ngIf="!isLoading" class="my-3">Submit your answers: {{courseName}}</h1>
      
        <hw-spinner class="px-3 py-1" [spinnerMessage]="'loading...'" [faColour]="'#8031A7'"
        [boxColour]="'#8031A7'" *ngIf="isLoading" [boxPadding]="'5px 15px'"></hw-spinner>
       
        <div class="card" >
          <div class="card-body" *ngIf="!isLoading">           
            <p>
              You can upload one file with your answers. For more information please refer to the below before submitting: 
            </p> 
            <ul>
                <li><a [href]="generalInfoURL" target="_blank">Final Online assessment instructions </a></li>
                <li><a [href]="technicalGuideURL" target="_blank">Student technical guide to final online assessments </a></li>
                <li><a [href]="checkListURL" target="_blank">Student checklist: Before you submit your answers</a></li>
              </ul>  
            <ul class="list-unstyled" >
              <li class="list-group-item">
                <div class="row">                  
                  <div class="col-8 col-sm-10">
                    By submitting your work, you confirm that this work is your own and is expressed in your own words. You confirm that any use made within it of the works of other authors in any form (e.g. ideas, text, equations, figures, tables, other forms of data) are properly acknowledged and referenced at the point of their use. If appropriate, you confirm that a list of the references employed is included (not required for numeracy-based questions). 
                  </div>
                  <div class="col-4 col-sm-2" style="text-align: right;">
                    <label class="switch">
                      <input type="checkbox" [checked]="hasAccepted" (change)="hasAccepted = !hasAccepted">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
  
            <div class="row justify-content-end">
              <div class="col-12 text-right">
                <div class="btn btn-secondary wrapButtonText mb-2" (click)="closeConsent()">Return to list of assessments</div>
                <div class="btn btn-primary wrapButtonText mb-2 ml-3" (click)="goToTurnitin()">Continue</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div *ngIf="consentRequiredMessageTriggered && !hasAccepted" role="alert" class="alert alert-danger text-center"><i class="fa fa-lg fa-exclamation-triangle fa-fw"></i> Before continuing, please confirm that this work is your own by agreeing to the statement above.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


 <div *ngIf="showTurnitinWarning === true" role="alert" class="alert alert-warning">
    <h4>
        <i class="fa fa-lg fa-exclamation-triangle fa-fw"></i> TurnItIn System Warning
    </h4> 
    <p class="d-md-block">
        Unfortunately, the TurnItIn system is currently experiencing issues. 
        If you are unable to upload directly to TurnItIn, please email your answers in a single attachment as instructed in the 
        <i>Your Exam Answer File</i> section of the Technical Guide to 
        <a [href]="getMailToLink()"> examscripts@hw.ac.uk </a> before the exam closing date and time.
    </p>
    <p class="rd-md-block">
        Please remember to name your file for attaching to the email as follows {{getPaperName()}}
    </p>
</div>
 

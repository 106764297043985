<ul role="menu">

    <ng-container *ngIf="displayMenu && topMenu">
        <li #menubutton id="section-{{i}}" *ngFor="let section of topMenu.sections; let i= index" class="navbar-item top">
            
            <a [href]="onePortalDashboard" class="navbar-link" role="menuitem" aria-expanded="false" *ngIf="!section.groups">
                <span class="header-icon-large {{section.icon}}"></span>
                <span class="menu-label">{{section.title}}</span>
            </a>

            <div class="btn-group" dropdown  *ngIf="section.groups">
                <button dropdownToggle type="button" class="btn btn-light btn-sm preferences-button navbar-link"
                    [attr.aria-controls]="'button-group-' + i">
                    <span *ngIf="!section.usephoto" class="header-icon-large {{section.icon}}"></span>
                    <img *ngIf="section.usephoto" class="avatar rounded-circle" alt="View account"
                        [src]="avatar| domSanitizerPipe: 'src'" />
                    <div>
                        <span class="menu-label">{{section.title}} <span
                                class="header-icon-small icon-gecko-scroll-down"></span>
                        </span>
                    </div>
                </button>


                <ul #submenus id="submenu-{{i}}" *dropdownMenu class="dropdown-menu dropdown-menu-dynamic"
                    [ngClass]="menuCssClass" role="menu" aria-labelledby="button-studies">
                    <div [ngStyle]="{'min-width': section.groups.length*250+ 'px', 'overflow': 'hidden'}"
                        style="min-width: 900px;" class="row studies-row">
                        <div *ngFor="let group of section.groups" class="col-12"
                            [ngClass]="(section.groups.length==2)? 'col-lg-6': (section.groups.length==3)? 'col-lg-4' : 'col-lg-3'">
                            <li class="column"><span
                                    [ngStyle]="{'border-bottom': !group.items? 'none': '1px solid #5b6770'}"
                                    [ngClass]="{'menu-header': group.title}" data-toggle="tooltip"
                                    data-placement="bottom">{{group.title}}</span>
                                <div *ngIf="group.text" [innerHTML]="group.text"></div>
                                <ul *ngIf="group.items">
                                    <li *ngFor="let item of group.items" class="navbar-item subitem" role="none">
                                        <a *ngIf="!item.isConnectUsLink" role="menuitem"
                                            href="{{item.link}}">{{item.title}}</a>
                                        <a *ngIf="item.isConnectUsLink" role="menuitem"
                                            [routerLink]="[item.link]">{{item.title}}</a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                    </div>
                </ul>
            </div>

        </li>
    </ng-container>    
</ul>
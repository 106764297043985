import { BaseObject } from '../../core/storable-object';

export class User extends BaseObject {
    username: string;
    password: string;
    isAuthenticated: boolean;

    isLoggedOut: boolean;
    roles: Array<string> = new Array<string>();

    fullName: string;
    email: string;

    // add programme and course related properties

    isWorkingOffline: boolean;

    constructor() {
        super();
    }
}



export class UserAccount  extends BaseObject{
    username: string;
    showPhotographPublicly: boolean;
    showCountryOfResidencePublicly: boolean;
    roles: Array<UserRole> = new Array<UserRole>();
    isWorkingOffline: boolean;
    shouldResetPassword: boolean;
    shouldAgreeToTermsAndConditions: boolean;
}


export enum UserRole {
    Student = 'Student',
    Faculty = 'Faculty',
    AlpStaff = 'AlpStaff',
    RegionalPartnerStaff = 'RegionalPartnerStaff',
    PortalAdministrator = 'PortalAdministrator',
    Demo = 'Demo',
    Staff = 'Staff'
}

export class CourseEnrolment {
    courseCode: string;
    courseName: string;
    isFacultyMemberForCourse: boolean;
    isLearningPlatformEnabled: boolean;
    isAvailableOffline: boolean;
    isDownloaded: boolean;
    numberOfDownloadedModules: number;
    fullLink: string;
}

export class Photograph {
    data: string;
    type: string;
}

export class ProgrammeOfStudy {
    programmeName: string;
    programmeCode: string;
}

export class AcademicRecord {
    programmeOfStudy: ProgrammeOfStudy;
}

export class PersonalDetails {
    givenNames: string;
    familyName: string;
    preferredName: string;
    emailAddress: string;
    countryOfResidence: string;
    photograph: Photograph;
}

export class AuthenticationDetails {
    username: string;
    password: string;
    isAuthenticated: boolean;
    isLoggedOut: boolean;
}

export class EnrolmentDetails {
    courseEnrolments: Array<CourseEnrolment> = new Array<CourseEnrolment>();
}

export class UserProfile extends BaseObject {
    userAccount: UserAccount;
    authenticationDetails: AuthenticationDetails;
    personalDetails: PersonalDetails;
    enrolmentDetails: EnrolmentDetails;
    academicRecord: AcademicRecord;

}
import { Injectable, EventEmitter } from '@angular/core';
import { ApplicationEvent, TypedApplicationEvent } from '@app/common/models/application-event';
import { ApplicationEventType } from '@app/common/models/application-event-type';

@Injectable({
  providedIn: 'root',
})
export class ApplicationEventDispatcher {
  public commonApplicationEvent: EventEmitter<ApplicationEvent>;

  constructor() {
    this.commonApplicationEvent = new EventEmitter<ApplicationEvent>();
  }

  public processCommonApplicationEvent(message: string, type: ApplicationEventType): void{
    const event = new ApplicationEvent();
    event.message = message;
    event.type = type;
    this.commonApplicationEvent.emit(event);
  }
}
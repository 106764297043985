import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subscriber } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { OperationResponse } from '../common/models/operation-response';
import { BaseObject, IBaseObject } from './storable-object';
import { PagedRequest } from '../common/models/paged-request';
import { PagedResult } from '../common/models/paged-result';
import { ConfigurationHelperService } from '../common/app-configuration/configuration-helper.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceBase {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        private toastr: ToastrService,
        private configurationService:ConfigurationHelperService
    ) {
    }

    /**
     * Retrieves the server base uri from the app settings
     */
    public getApiServerUri(): string {
        return this.configurationService.getApiServerUri();
    }
   
    public getObjects<T extends BaseObject>(uriFilter: string): Observable<OperationResponse<T[]>> {
        uriFilter = uriFilter.toLowerCase();

        const observable = new Observable<OperationResponse<T[]>>(observer => {
                this.getObjectsFromRemoteStore<T>(uriFilter, observer);
        });
        return observable;
    }
   
    protected getObjectsFromRemoteStore<T extends BaseObject>(uriFilter: string, observer: Subscriber<OperationResponse<T[]>>): void {
        const urlFilter = this.getApiServerUri() + uriFilter;

        this.http.get<OperationResponse<T[]>>(urlFilter, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T[]>) => {

                    if (response.operationSucceeded === false) {
                        console.log('getObjectsFromRemoteStore', response);
                        const userDoesNotHaveAccess = response.status === 'Forbidden';
                        const message = userDoesNotHaveAccess ? 'Sorry - you do not have access to this page' : 'You may not be viewing the most recent information';
                        this.toastr.error(message, 'Something went wrong');
                        observer.error(`request to ${urlFilter} failed ${userDoesNotHaveAccess ? ' - ' + response.status : ''}`);
                    }
                    else {
                        if (response.data === null) {
                            response.data = new Array<T>();
                        }

                        observer.next(<OperationResponse<T[]>>response);
                        observer.complete();
                    }
                },
                error => {
                    console.log('getObjectsFromRemoteStore', error);
                    this.toastr.error('You may not be viewing the most recent information', 'Something went wrong');
                }
            );
    }
    
    public getPagedObjects<T extends BaseObject>(request: PagedRequest<T>, 
        headers: HttpHeaders = null): Observable<OperationResponse<PagedResult<T>>> {

        const observable = new Observable<OperationResponse<PagedResult<T>>>(observer => {
            
            this.getPagedObjectsFromRemoteStore<T>(request, observer, headers);
           
        });
        return observable;
    }

    protected getPagedObjectsFromRemoteStore<T extends BaseObject>(request: PagedRequest<T>, 
        observer: Subscriber<OperationResponse<PagedResult<T>>>, 
        httpHeaders: HttpHeaders = null): void {
        const urlFilter = this.getApiServerUri() + request.getRequestFilter();


        this.http.get<OperationResponse<PagedResult<T>>>(urlFilter, { headers: httpHeaders, withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<PagedResult<T>>) => {
                    if (response.operationSucceeded === false || response.data == null) {
                        this.toastr.error('You may not be viewing the most recent information', 'Something went wrong');
                        observer.error(`request to ${urlFilter} failed`);
                    }
                    else {
                        if (response.data.items == null) {
                            response.data.items = new Array<T>();
                        }

                        observer.next(<OperationResponse<PagedResult<T>>>response);
                        observer.complete();
                    }
                },
                error => {
                    console.log('getPagedObjectsFromRemoteStore', error);
                    this.toastr.error('You may not be viewing the most recent information', 'Something went wrong');
                    observer.error(`request to ${urlFilter} failed`);
                }
            );
    }

    public getObject<T extends BaseObject>(uri: string): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();

        const observable = new Observable<OperationResponse<T>>(observer => {
            this.getObjectFromRemoteStore<T>(uri, observer);
        });

        return observable;
    }

    protected getObjectFromRemoteStore<T extends BaseObject>(uri: string, observer: Subscriber<OperationResponse<T>>): void {
        uri = uri.toLowerCase();

        this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T>) => {
                    if (response.operationSucceeded === false || response.data == null) {
                        observer.error(<OperationResponse<T>>response);
                    }
                    else {                       
                        observer.next(<OperationResponse<T>>response);
                    }

                    observer.complete();
                },
                error => {
                    observer.error(error);
                    observer.complete();
                }
            );
    }

    public getObjectFromRemoteStoreOnly<T extends IBaseObject>(uri: string): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();
        return this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, { withCredentials: true })
            .pipe(catchError(this.handleHttpError));
    }


    public async getObjectFromRemoteStoreSync<T extends BaseObject>(uri: string) {
        uri = uri.toLowerCase();

        const result = await this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, { withCredentials: true })
            .pipe(catchError(this.handleHttpError)).toPromise();

        return <OperationResponse<T>>result;
    }

    public setObject<T extends BaseObject>
        (data: T, uri: string): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();
        const url = this.getApiServerUri() + uri;

        const observable = new Observable<OperationResponse<T>>(observer => {
                this.setObjectInRemoteStore(data, url, observer);
        });

        return observable;
    }

    protected setObjectInRemoteStore<T extends BaseObject>(data: T, uri: string, observer: Subscriber<OperationResponse<T>>): void {
        if (data === undefined || data === null || uri === undefined || uri === null) {
            observer.error('Storable item or URI cannot be null or undefined');
            observer.complete();
            return;
        }
        uri = uri.toLowerCase();

        this.http.post<OperationResponse<T>>(uri, data, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T>) => {

                    if (response && response.operationSucceeded) {
                        if (response.data) {
                            // response.data.uri = data.uri;
                            data.uri = response.data.uri;
                        }
                        observer.next(<OperationResponse<T>>response);
                        observer.complete();
                    } else {
                        console.log(response)
                        this.toastr.error('Unable to perform save operation', 'Something went wrong');
                        observer.error(`Unable to perform save operation for ${uri}`);
                    }
                },
                error => {
                    observer.error(error);
                });
    }



    public postObjectToRemoteStoreOnly<T>(data: any, uri: string): Observable<OperationResponse<T>> {

        if (data === undefined || data === null || uri === undefined || uri === null) {
            console.error('Data or URI cannot be null or undefined');
            return null;
        }
        uri = uri.toLowerCase();
        return this.http.post<OperationResponse<T>>(uri, data, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
    }

    public handleHttpError(error: HttpErrorResponse) {
        console.log(error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}; ` +
                `error body was: ${error.error}`);
        }
        // return an observable with a user-facing error message.
        return throwError(
            'Something went wrong; please try again later.');
    }

    public notifyApplicationError(message: string, title: string) {
        this.toastr.error(message, title);
    }
}

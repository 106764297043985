import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExamListComponent } from './exam-list/exam-list.component';
import { TurnitinIntegrationComponent } from './turnitin-integration/turnitin-integration.component';
import { AuthGuard } from './common/security/auth-guard';
import { OwnershipStatementComponent } from './ownership-statement/ownership-statement.component';
import { TurnitinFormComponent } from './turnitin-form/turnitin-form.component';
import { FacultyExamListComponent } from './faculty-exam-list/faculty-exam-list.component';

const routes: Routes = [
  { path: '', redirectTo: 'exams', pathMatch: 'full' },
  { path: 'exams', component: ExamListComponent, canActivate: [AuthGuard] },
  { path: 'faculty-exams', component: FacultyExamListComponent, canActivate: [AuthGuard] },
  { path: 'ownership-statement/:sessionCode/:courseCode/:isoCode', component: OwnershipStatementComponent, canActivate: [AuthGuard] },
  { path: 'turnitin/:sessionCode/:courseCode/:isoCode', component: TurnitinIntegrationComponent, canActivate: [AuthGuard] },
  { path: 'turnitin-form/:sessionCode/:courseCode/:isoCode', component: TurnitinFormComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ExamsService } from '@app/services/exams.service';
import { ExaminationEntry } from '@app/models/examination-entry';

@Component({
  selector: 'hw-exam-list-item',
  templateUrl: './exam-list-item.component.html',
  styleUrls: ['./exam-list-item.component.scss']
})
export class ExamListItemComponent implements OnInit {
  @Input() exam: ExaminationEntry;

  constructor(private examsService: ExamsService, private router: Router) { }

  ngOnInit(): void {
  }

  isExaminationInProgress(exam: ExaminationEntry): boolean{
    return this.isExaminationOpen(exam) === true && this.isExaminationClosed(exam) === false;
  }

  isExaminationOpen(exam: ExaminationEntry): boolean{
    return exam.hasOpen;
  }

  isExaminationClosed(exam: ExaminationEntry): boolean{
    return exam.isClosed;
  }

  getExamPaperUrl(exam: ExaminationEntry): string{
    return this.examsService.examPaperUrl(exam);
  }

  showConsent(exam: ExaminationEntry): void {
    this.router.navigate(['/ownership-statement', exam.sessionCode, exam.courseCode, exam.languageCode],
    {queryParams: {courseName: exam.courseName}});
  }

}

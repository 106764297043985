<div class="row border-bottom mt-3 pb-3" *ngIf="exam != null">
    <div class="col col-12 col-xl-3 col-lg-2"><b>{{exam.courseName}} ({{exam.languageName }})</b></div>
    <div class="col col-12 col-lg-3">Opens: {{exam.openingDateTime | date:'MMMM d, y, h:mm a' }} UK time<br>
        Closes: {{exam.closingDateTime | date:'MMMM d, y, h:mm a' }} UK time</div>
    <div class="col col-12 col-lg-3">
        <p>
            <b *ngIf="isExaminationInProgress(exam)" class="available">Assessment is open </b>
            <i *ngIf="!isExaminationInProgress(exam) && !isExaminationClosed(exam)" class="upcoming">Assessment paper
                will be available here from {{exam.openingDateTime | date:'MMMM d, y, h:mm a' }} UK Time </i>
            <b *ngIf="isExaminationClosed(exam)" class="unavailable">Assessment is closed </b>
        </p>
    </div>
    <div class="col col-12 col-xl-3 col-lg-4">
        <ng-container *ngIf="isExaminationInProgress(exam)">
            <a class="btn btn-primary wrapButtonText mb-2 mr-lg-0 mr-2" [href]="getExamPaperUrl(exam)"> Download
                assessment paper </a><span class="btn btn-primary wrapButtonText mb-2" (click)="showConsent(exam)">
                Submit your answers </span>
        </ng-container>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ExaminationEntry } from '@app/models/examination-entry';
import { ExamsService } from '@app/services/exams.service';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';

@Component({
  selector: 'hw-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit {

  isExamListLoading: boolean;
  exams: Array<ExaminationEntry>;
  practiceExams: Array<ExaminationEntry>;

  generalInfoURL: string;
  generalInfoURLAlt: string;
  technicalGuideURL: string;
  checkListURL: string;

  constructor(private examsService: ExamsService, private configHelper: ConfigurationHelperService) { }

  ngOnInit(): void {
    this.isExamListLoading = true;

    this.examsService.getUpcomingExams().subscribe(x => {
       this.isExamListLoading = false;

       const examList = x.operationSucceeded === true
                      ? x.data
                      : new Array<ExaminationEntry>();
       this.exams = examList.filter(e => !e.isPracticeExamination);
       this.practiceExams = examList.filter(e => e.isPracticeExamination);
    }, error => {
      this.isExamListLoading = false;
      this.exams = new Array<ExaminationEntry>();
      console.log(error);
    });

    this.generalInfoURL = this.configHelper.getTakeAtHomeGeneralInfo();
    this.generalInfoURLAlt = this.configHelper.getTakeAtHomeGeneralInfoAlt();
    this.technicalGuideURL = this.configHelper.getTakeAtHomeTechnicalGuide();
    this.checkListURL = this.configHelper.getTakeAtHomeChecklist();
  }

  hasUpcomingExams(): boolean {
    return this.exams != null && this.exams.length > 0;
  }

  hasPracticeExams(): boolean {
    return this.practiceExams != null && this.practiceExams.length > 0;
  }
  
  public isIE(): boolean {
    return /msie\s|trident\//i.test(window.navigator.userAgent);
  }
}

<hw-spinner class="px-3 py-1" [spinnerMessage]="'loading...'" [faColour]="'#8031A7'"
              [boxColour]="'#8031A7'" *ngIf="isLoading || isLoadingTurnitingRequst" [boxPadding]="'5px 15px'"></hw-spinner>


<ng-container *ngIf="!isLoading && !isLoadingTurnitingRequst">

    <ng-container *ngIf="isValidPageRequest && isValidTurnitinRequest">

        <form ngNoForm #TurnitinForm target="_self"  action="{{ltiRequest.url}}" method="POST" >
        
            <input name="anonymous_marking_enabled" type="hidden" [value]="ltiRequest.anonymous_marking_enabled" >
            <input name="assignmentid" type="hidden" [value]="ltiRequest.assignmentid" >
            <input name="context_id" type="hidden" [value]="ltiRequest.context_id" >
            <input name="context_label" type="hidden" [value]="ltiRequest.context_label" >
            <input name="context_title" type="hidden" [value]="ltiRequest.context_title" >
            <input name="ext_ims_lis_basic_outcome_url" type="hidden" [value]="ltiRequest.ext_ims_lis_basic_outcome_url" >
            <input name="ext_lti_assignment_id" type="hidden" [value]="ltiRequest.ext_lti_assignment_id" >
            <input name="ext_outcome_data_values_accepted" type="hidden" [value]="ltiRequest.ext_outcome_data_values_accepted" >
            <input name="ext_outcome_result_total_score_accepted" type="hidden" [value]="ltiRequest.ext_outcome_result_total_score_accepted" >
            <input name="ext_outcome_submission_submitted_at_accepted" type="hidden" [value]="ltiRequest.ext_outcome_submission_submitted_at_accepted" >
            <input name="ext_outcomes_tool_placement_url" type="hidden" [value]="ltiRequest.ext_outcomes_tool_placement_url" >
            <input name="ext_roles" type="hidden" [value]="ltiRequest.ext_roles" >
            <input name="launch_presentation_document_target" type="hidden" [value]="ltiRequest.launch_presentation_document_target" >
            <input name="launch_presentation_locale" type="hidden" [value]="ltiRequest.launch_presentation_locale" >
            <input name="lis_person_contact_email_primary" type="hidden" [value]="ltiRequest.lis_person_contact_email_primary" >
            <input name="lis_person_name_family" type="hidden" [value]="ltiRequest.lis_person_name_family" >
            <input name="lis_person_name_full" type="hidden" [value]="ltiRequest.lis_person_name_full" >
            <input name="lis_person_name_given" type="hidden" [value]="ltiRequest.lis_person_name_given" >
            <input name="lti_message_type" type="hidden" [value]="ltiRequest.lti_message_type" >
            <input name="lti_version" type="hidden" [value]="ltiRequest.lti_version" >
            <input name="oauth_callback" type="hidden" [value]="ltiRequest.oauth_callback" >
            <input name="oauth_consumer_key" type="hidden" [value]="ltiRequest.oauth_consumer_key" >
            <input name="oauth_nonce" type="hidden" [value]="ltiRequest.oauth_nonce" >
            <input name="oauth_signature" type="hidden" [value]="ltiRequest.oauth_signature" >
            <input name="oauth_signature_method" type="hidden" [value]="ltiRequest.oauth_signature_method" >
            <input name="oauth_timestamp" type="hidden" [value]="ltiRequest.oauth_timestamp" >
            <input name="oauth_version" type="hidden" [value]="ltiRequest.oauth_version" >
            <input name="resource_link_id" type="hidden" [value]="ltiRequest.resource_link_id" >
            <input name="resource_link_title" type="hidden" [value]="ltiRequest.resource_link_title" >
            <input name="roles" type="hidden" [value]="ltiRequest.roles" >
            <input name="submission_title" type="hidden" [value]="ltiRequest.submission_title" >
            <input name="tool_consumer_info_product_family_code" type="hidden" [value]="ltiRequest.tool_consumer_info_product_family_code" >
            <input name="tool_consumer_info_version" type="hidden" [value]="ltiRequest.tool_consumer_info_version" >
            <input name="tool_consumer_instance_contact_email" type="hidden" [value]="ltiRequest.tool_consumer_instance_contact_email" >
            <input name="tool_consumer_instance_guid" type="hidden" [value]="ltiRequest.tool_consumer_instance_guid" >
            <input name="tool_consumer_instance_name" type="hidden" [value]="ltiRequest.tool_consumer_instance_name" >
            <input name="user_id" type="hidden" [value]="ltiRequest.user_id" >                               
            <input type="submit" value="Go to turnitin" style="display: none;" />
        </form>
        
    </ng-container>

    <div class="alert alert-danger beta-version" role="error" *ngIf="!isValidPageRequest || !isValidTurnitinRequest" >
        <h4>
        <i class="fas fa-fw fa-exclamation-circle fa-lg"></i> Something went wrong
        </h4>
        <p>
        {{ltiBadRequestMessage}}
        </p>        
    </div> 

</ng-container>
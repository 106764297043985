import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { LtiSettings } from '@app/models/lti-settings';
import { TurnitinLtiService } from '@app/services/turnitin-lti.service';

@Component({
  selector: 'hw-lti-settings',
  templateUrl: './lti-settings.component.html',
  styleUrls: ['./lti-settings.component.scss']
})
export class LtiSettingsComponent implements OnInit {

  initialLtiSettings: boolean;
  ltiSettings: LtiSettings;
  showContent: boolean;
  disableButtons: boolean;

  constructor(private turnitinSerice: TurnitinLtiService) { }

  ngOnInit(): void {
    this.showContent = false;
    this.disableButtons = true;

    this.turnitinSerice.getLtiStatus().subscribe(x => {
      if (x.operationSucceeded && x.data != null){
        this.showContent = true;
        this.ltiSettings = x.data;
        this.initialLtiSettings = x.data.isTurnitinAvailable;
        this.disableButtons = false;
      } else {
        this.showContent = false;
        this.disableButtons = true;
      }
    }, err => {
      this.showContent = false;
      this.disableButtons = true;
    });
  }

  cancel(): void{
    this.ltiSettings.isTurnitinAvailable = this.initialLtiSettings;
  }

  save(): void{
    this.disableButtons = true;
    this.turnitinSerice.setLtiStatus(this.ltiSettings).subscribe(x => {
      if (x.operationSucceeded){
        this.initialLtiSettings = this.ltiSettings.isTurnitinAvailable;
      }
      this.disableButtons = false;
    }, err => {
      this.disableButtons = false;
    });
  }
}
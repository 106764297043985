export class StaticText {
    
    static bodyColour = '#5B6770';
    static defaultSpinnerMessage = 'loading your data';
    static defaultSpinnerFont = 1.1;
    static defaultSpinnerPadding = '5%';
  
    static currentUserUrl = '/currentuser';
    static currentUserNoSlash = 'currentuser';

    
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TurnitinStateService } from '@app/services/turnitin-state.service';
import { UserService } from '@app/common/security/user.service';
import { UserProfile, UserRole } from '@app/common/models/user';

@Component({
  selector: 'hw-turnitin-integration',
  templateUrl: './turnitin-integration.component.html',
  styleUrls: ['./turnitin-integration.component.scss']
})
export class TurnitinIntegrationComponent implements OnInit, OnDestroy {
  isValidPageRequest: boolean;
  returnLink: string = "/faculty-exams";

  isLoading: boolean;
  courseName: string;
  courseCode: string;
  languageCode: string;
  sessionCode: string;

  queryStringSubscription: Subscription;
  routeValuesSubscription: Subscription;

  turinitinFormUri: string;
  currentUserProfile: UserProfile;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private turnitinStateService: TurnitinStateService,
              private userService: UserService
              ){

              }

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe({
    next:  (x:UserProfile) => {this.currentUserProfile = x; },
    error: () => {},
    complete:  () => {

        this.checkIfPageIsStillLoading();

        this.queryStringSubscription = this.route.queryParams
          .subscribe(params => {
console.log('QUERY PARAMS:' + params.courseName);
            if (params != null){
              this.courseName = params.courseName;
              this.checkIfPageIsStillLoading();
            }
          });

        this.routeValuesSubscription = this.route.params.subscribe(params => {
              this.sessionCode = params.sessionCode;
              this.courseCode = params.courseCode;
              this.languageCode = params.isoCode;

              this.checkIfPageIsStillLoading();
            });

      }});
  }

  getStudentId(): string{
    if (this.currentUserProfile && this.currentUserProfile.userAccount && 
      this.currentUserProfile.userAccount.username){
        return this.currentUserProfile.userAccount.username;
      }
    return '';
  }

  checkIfPageIsStillLoading(): void{
    let areAllNeededValuesPresent = false;

    if (this.sessionCode != null &&
      this.languageCode != null &&
      this.courseCode != null &&
      this.courseName != null){
        areAllNeededValuesPresent = true;
        this.checkValidityOfPageRequest();
      }

    this.isLoading = !areAllNeededValuesPresent;
  }

  isInStudentRole(): boolean{
    if (this.currentUserProfile != null
      && this.currentUserProfile.userAccount != null
      && this.currentUserProfile.userAccount.roles != null
      && this.currentUserProfile.userAccount.roles.findIndex(x => x === UserRole.Student) > -1){
        return true;
      }
    return false;
  }

  isInFacultyOrStaff(): boolean{
    if (this.currentUserProfile != null
      && this.currentUserProfile.userAccount != null
      && this.currentUserProfile.userAccount.roles != null
      && (this.currentUserProfile.userAccount.roles.findIndex(x => x === UserRole.Faculty) > -1
        || this.currentUserProfile.userAccount.roles.findIndex(x => x === UserRole.Staff) > -1)) {
        return true;
      }
    return false;
  }

  navigateToOwnershipStatementPageOrToFacultyExamPAge(): void{

    if (this.isInFacultyOrStaff() === true){
      this.router.navigate(['/faculty-exams']);
    }

    if (this.isInStudentRole() === true){
      this.router.navigate(['/ownership-statement', this.sessionCode, this.courseCode, this.languageCode],
        {queryParams: {courseName: this.courseName}});
    }
}

public isIE(): boolean {
  return /msie\s|trident\//i.test(window.navigator.userAgent);
}

navigateToExamList(): void{

  if (this.isInFacultyOrStaff() === true){
    this.router.navigate(['/faculty-exams']);
  }

  if (this.isInStudentRole() === true){
    this.router.navigate(['/exams']);
  }
}

  getTurnitinFormUri(): string{
    return `/turnitin-form/${this.sessionCode}/${this.courseCode}/${this.languageCode}`;
  }

  checkValidityOfPageRequest(): void {
    if (this.turnitinStateService.hasAgreedToOwnershipStatement === false){
      this.isValidPageRequest = false;
      this.navigateToOwnershipStatementPageOrToFacultyExamPAge();
      return;
    }

    const currentExaminationEntry = this.turnitinStateService.currentExaminationEntry;

    if (currentExaminationEntry != null &&
      currentExaminationEntry.sessionCode === this.sessionCode &&
      currentExaminationEntry.courseCode === this.courseCode &&
      currentExaminationEntry.languageCode === this.languageCode &&
      currentExaminationEntry.courseName === this.courseName){
        this.isValidPageRequest = true;
        this.turinitinFormUri = this.getTurnitinFormUri();
        return;
      }

    this.isValidPageRequest = false;
    this.navigateToOwnershipStatementPageOrToFacultyExamPAge();
  }

  ngOnDestroy(): void {
    if (this.queryStringSubscription != null) {
      this.queryStringSubscription.unsubscribe();
    }
    if (this.routeValuesSubscription != null){
      this.routeValuesSubscription.unsubscribe();
    }
  }

  getExamPaperFileName(): string{
    const studentId = this.getStudentId();

    if (studentId === '') { return ''; }

    return `${studentId}_${this.courseName}_FOA`;
  }
}

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApplicationEventDispatcher } from '@app/common/services/application-event-dispatcher.service';
import { ApplicationEventType } from '@app/common/models/application-event-type';
import { OperationResponse } from '../models/operation-response';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  hasBypassHeader : boolean = false;
  isDownloadRequest : boolean = false;

  constructor(public applicationEventDispatcher: ApplicationEventDispatcher) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
       
        if (event instanceof HttpResponse) {

            const httpResponse = event as HttpResponse<OperationResponse<any>>;

            if (httpResponse != null && httpResponse.body != null && httpResponse.body.status === 'Unauthorized'){

                const message = httpResponse.body.notifications != null && httpResponse.body.notifications.length > 0
                ? httpResponse.body.notifications[0].message
                : 'You need to login';

                this.applicationEventDispatcher.processCommonApplicationEvent(message, ApplicationEventType.AuthenticationRequired);
            } 
        } 
       
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        console.info('INTERCEPTED HttpErrorResponse with error =', err, ';');
       
        return throwError(() => err);
      }));
  }
}
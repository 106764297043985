<hw-spinner class="px-3 py-1" [spinnerMessage]="'loading your application...'" [faColour]="'#8031A7'"
    [boxColour]="'#8031A7'" *ngIf="headerLoading" [boxPadding]="'5px 15px'"></hw-spinner>


<header class="portal-header" *ngIf="!headerLoading">
    <div class="ebs-navbar">
        <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="navbar-brand" *ngIf="isTheLoginScreen" style="display: flex !important;" >
                <a [href]="studentDashboardUrl" class="logo" >
                    <img src="assets/img/logo.svg" alt="Edinburgh Business School">
                </a>
            </div>
            <div class="navbar-brand" *ngIf="!isTheLoginScreen">
                <a [href]="studentDashboardUrl" class="logo" >
                    <img src="assets/img/logo.svg" alt="Edinburgh Business School">
                </a>
            </div>
            <div class="navbar-menu">
                <div class="navbar-end">
                    <hw-top-menu [avatar]="avatar" [displayMenu]="displayMenu" [onePortalDashboard]="studentDashboardUrl">
                    </hw-top-menu>
                </div>
            </div>
        </nav>
    </div>
</header>
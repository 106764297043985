<div id="page-wrapper">
  <hw-page-header class="hidden-print" *ngIf="!hideHeader"></hw-page-header> 
  <ng-container *ngIf="showRequiredLogin">
    <div class="main-content">  
      <div class="scrollable full-height-minus-header">
        <div class="container px-md-0">
          <p></p>
          <div class="card" >
            <div class="card-body">           
              <div role="alert" class="alert alert-danger"><i class="fa fa-lg fa-exclamation-triangle fa-fw"></i> {{requiredLoginMessage}}</div>
              <div class="row justify-content-end">
                <div class="col-10 col-md-10 text-right"></div>
               
                <div class="col-12 col-md-12 text-right">
                  <div class="btn btn-primary wrapButtonText " (click)="goToLoginPage()"> Go to login page </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>         
  </ng-container>
  <ng-container *ngIf="!showRequiredLogin">
    <router-outlet></router-outlet>  
  </ng-container>  
</div>
import { Component, OnInit } from '@angular/core';
import { ExamsService } from '@app/services/exams.service';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { Router } from '@angular/router';
import { ExaminationTimetableEntry } from '@app/models/examination-timetable-entry';
import { ExaminationEntry } from '@app/models/examination-entry';
import { TurnitinStateService } from '@app/services/turnitin-state.service';

@Component({
  selector: 'hw-faculty-exam-list',
  templateUrl: './faculty-exam-list.component.html',
  styleUrls: ['./faculty-exam-list.component.scss']
})
export class FacultyExamListComponent implements OnInit {
  isTimetableListLoading: boolean;
  examTimetableEntries: Array<ExaminationTimetableEntry>;
  examTimetableEntriesCloned: Array<ExaminationTimetableEntry>;
  filteredTimetableEntries: Array<ExaminationTimetableEntry>;
  session: string;
  sessions: Array<string>;
  userIsUnauthorized: boolean;


  constructor(private examsService: ExamsService, private configHelper: ConfigurationHelperService,
    private router: Router, private turnitinStateService: TurnitinStateService) { }

  ngOnInit(): void {
    this.isTimetableListLoading = true;

    this.examsService.getExamsForFaculty().subscribe(x => {
      this.examTimetableEntries = x.operationSucceeded === true
        ? x.data
        : new Array<ExaminationTimetableEntry>();
      this.getSessions();

      if(this.examTimetableEntries.length > 0){

        this.setDefaultSession();

        //this.session = this.examTimetableEntries[0].sessionCode;
        this.filterBySession(this.session);
      }     
      this.isTimetableListLoading = false;
    }, error => {
      this.isTimetableListLoading = false;
      this.examTimetableEntries = new Array<ExaminationTimetableEntry>();
      if(error?.indexOf('Forbidden') > -1) {
        this.userIsUnauthorized = true;
      }
      console.log(error);
    });
  }

  public filterBySession(session: string) {
    this.filteredTimetableEntries = new Array<ExaminationTimetableEntry>();
    for (const entry of this.examTimetableEntries) {
      if (session === entry.sessionCode) {
        this.filteredTimetableEntries.push(entry);
      }
    }
    this.session = session;
  }

  getSessions() {
    this.sessions = new Array<string>();
    for (const entry of this.examTimetableEntries) {
      if (!this.sessions.some(x => x == entry.sessionCode)) {
        this.sessions.push(entry.sessionCode);
      }
    }
  }

  setDefaultSession() {

    this.examTimetableEntriesCloned = JSON.parse(JSON.stringify(this.examTimetableEntries));
    //Sorting the examination timetable entries Descending so we can retrieve 
    //the most recent examination entry that has passed which is closest to the current date
    this.examTimetableEntriesCloned.sort((a,b) => { return new Date(b.examinationDate) >= new Date(a.examinationDate) ? 1 : -1; } );

    //const currentDate = new Date('2020-10-15T00:00:00');
    const currentDate = new Date();

    const previousExaminationEntryIndex = this.examTimetableEntriesCloned.findIndex( x => {       
      return (new Date(x.examinationDate) <= currentDate); })
    const previousExaminationEntry = this.examTimetableEntriesCloned[previousExaminationEntryIndex];

    console.log(previousExaminationEntry);

    //Sorting the examination timetable entries Ascending so we can retrieve 
    //the upcoming examination entry that has passed which is closest to the current date
    this.examTimetableEntriesCloned.sort((a,b) => { return new Date(b.examinationDate) <= new Date(a.examinationDate) ? 1 : -1; } );

    const nextExaminationEntryIndex = 
    this.examTimetableEntriesCloned.findIndex( x => { return ( new Date(x.examinationDate) > currentDate); });

    const NextExaminationEntry = nextExaminationEntryIndex > -1 
                                                ? this.examTimetableEntriesCloned[nextExaminationEntryIndex]
                                                : null;

    console.log(currentDate);
    console.log(NextExaminationEntry);
    
    this.computeExamSessionCodeBasedOnCurrentDate(currentDate, previousExaminationEntry, NextExaminationEntry);
  }

  computeExamSessionCodeBasedOnCurrentDate(currentDate:Date, previousExaminationEntry: ExaminationTimetableEntry, 
    nextExaminationEntry: ExaminationTimetableEntry){

    if (nextExaminationEntry == null){
      this.session = previousExaminationEntry.sessionCode;
      return;
    } 

    const differenceInMonthsFromTheLastExamSession = currentDate.getMonth() - new Date(previousExaminationEntry.examinationDate).getMonth();

    console.log(`${differenceInMonthsFromTheLastExamSession} months since the last session`);

    if (differenceInMonthsFromTheLastExamSession <=2)
    {
      this.session = previousExaminationEntry.sessionCode;
      return;
    }

    const differenceInMonthsTillTheNextExamSession = new Date(nextExaminationEntry.examinationDate).getMonth() - currentDate.getMonth();

    if (differenceInMonthsTillTheNextExamSession <=1 )
    {
      this.session = nextExaminationEntry.sessionCode;
      return;
    }

    this.session = previousExaminationEntry.sessionCode;
  }

  goToTurnitin(exam: ExaminationTimetableEntry): void{
    let examinationEntry = new ExaminationEntry();
    examinationEntry.courseCode = exam.courseCode;
    examinationEntry.courseName = exam.courseName;
    examinationEntry.languageCode = exam.languageCode;
    examinationEntry.sessionCode = exam.sessionCode;
    
    this.turnitinStateService.setHasAgreedToOwnershipStatement(examinationEntry);
    this.router.navigate(['/turnitin', exam.sessionCode, exam.courseCode, exam.languageCode],
    {queryParams: {courseName: exam.courseName}});
  }

}

import { Component, OnInit, HostListener } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationCancel } from '@angular/router';

import { UserService } from '../../../common/security/user.service';
import { UserProfile } from '../../models/user';
import { HeaderLinks } from '../../models/header-links';
import { ConfigurationHelperService } from '../../app-configuration/configuration-helper.service';
import { Helpers } from '../../helpers';

@Component({
    selector: 'hw-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
    supportsServiceWorkers = true;
    userLoggedOff: boolean;
    headerLinks: HeaderLinks;
    userProfile: UserProfile;
    avatar: string;
    displayMenu: boolean;
    preferredName: string;
    isMobile = Helpers.isItMobile(window.innerWidth);
    headerLoading = false;
    isTheLoginScreen: boolean;
    studentDashboardUrl: string;

    constructor(
        private userService: UserService,
        private configurationHelperService: ConfigurationHelperService,
        private router: Router
    ) {
    }

    private readonly defaultAvatar = '../../../../../assets/img/avatar-temp.svg';

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = Helpers.isItMobile(window.innerWidth);
    }

    ngOnInit() {
        this.headerLoading = true;
        this.avatar = this.defaultAvatar;
        this.headerLinks = this.configurationHelperService.getHeaderLinks();

        this.studentDashboardUrl = this.configurationHelperService.getOnePortalDashboardUrl();

        this.supportsServiceWorkers = true;

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd
                || event instanceof NavigationCancel) {
                this.resetMenu();
            }
        });
    }

    private resetMenu() {
        this.setCurrentUser();
    }

    private setCurrentUser() {
        this.userService.getCurrentUser().subscribe(theUserProfile => {
            this.userProfile = theUserProfile;
            this.displayMenu = false;
            this.avatar = this.defaultAvatar;

            if (this.userProfile && this.userProfile.authenticationDetails != null
                && (this.userProfile.authenticationDetails.isAuthenticated)) {
                this.displayMenu = true;
                const photograph = this.userProfile.personalDetails != null ? this.userProfile.personalDetails.photograph : null;
                if (photograph != null && photograph.data != null && photograph.data !== '') {
                    this.avatar = `data:image/${photograph.type};base64,${photograph.data}`;
                }
            }
            this.headerLoading = false;
        },
            () => {
                this.headerLoading = false;
            },
            () => {
            }
        );
    }
}

import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PollingService } from './core/polling.service';
import { Subscription } from 'rxjs';
import { ApplicationEventDispatcher } from './common/services/application-event-dispatcher.service';
import { ApplicationEventType } from './common/models/application-event-type';
import { ConfigurationHelperService } from './common/app-configuration/configuration-helper.service';

@Component({
  selector: 'hw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy {
  title = 'assessment-app';
  hideHeader: boolean;
  showRequiredLogin: boolean;
  requiredLoginMessage: string;

  applicationEventSubscription: Subscription;

  constructor(private pollingService: PollingService,
              private applicationEventDispatcher: ApplicationEventDispatcher,
              private cdref: ChangeDetectorRef,
              private configHelper: ConfigurationHelperService,
              @Inject(DOCUMENT) private document: Document ){
  }

  ngOnDestroy(): void {
    if (this.applicationEventSubscription != null){
      this.applicationEventSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.checkIfHeaderNeedsToBeHidden();

    this.applicationEventSubscription = this.applicationEventDispatcher.commonApplicationEvent.subscribe(x => {
      if (x != null && x.type === ApplicationEventType.HideHeader){
        this.hideHeader = true;
        this.cdref.detectChanges();
      }

      if (x != null && x.type === ApplicationEventType.AuthenticationRequired){
        this.showRequiredLogin = true;
        this.requiredLoginMessage = x.message;
        this.cdref.detectChanges();
      }
    });

    this.pollingService.pollTheServer();
  }

  goToLoginPage(): void {
    this.document.location.href = this.configHelper.getOnePortalLoginPageUrl();
  }

  checkIfHeaderNeedsToBeHidden(): void{
    if (this.document.location.href.indexOf('turnitin-form') > -1){
      this.hideHeader = true;
    }
  }
}

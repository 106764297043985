import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageHeaderComponent } from './common/components/page-header/page-header.component';
import { SpinnerComponent } from './common/components/spinner/spinner.component';
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component';
import { AppConfigService } from './common/app-configuration/app-config.service';
import { initializeAppConfig } from './common/app-configuration/app-config.factory';
import { CookieService } from './common/cookies/cookie.service';
import { UserService } from './common/security/user.service';
import { ConfigurationHelperService } from './common/app-configuration/configuration-helper.service';
import { ExamListComponent } from './exam-list/exam-list.component';
import { TurnitinIntegrationComponent } from './turnitin-integration/turnitin-integration.component';
import { TopMenuComponent } from './common/components/top-menu/top-menu.component';
import { AuthGuard } from './common/security/auth-guard';
import { DomSanitizerPipe } from '@app/common/pipes/dom-sanitizer-pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OwnershipStatementComponent } from './ownership-statement/ownership-statement.component';
import { ConsentModalComponentComponent } from './consent-modal-component/consent-modal-component.component';
import { TurnitinFormComponent } from './turnitin-form/turnitin-form.component';
import { FacultyExamListComponent } from './faculty-exam-list/faculty-exam-list.component';
import { ExamListItemComponent } from './exam-list/exam-list-item/exam-list-item.component';
import { HttpRequestInterceptor } from '@app/common/security/http-request-interceptor';
import { LtiSettingsComponent } from './faculty-exam-list/lti-settings/lti-settings.component';
import { TurnitinWarningComponent } from './turnitin-integration/turnitin-warning/turnitin-warning.component';

@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    ExamListComponent,
    TurnitinIntegrationComponent,
    TopMenuComponent,
    PageNotFoundComponent,
    SpinnerComponent,
    DomSanitizerPipe,
    OwnershipStatementComponent,
    ConsentModalComponentComponent,
    TurnitinFormComponent,
    FacultyExamListComponent,
    ExamListItemComponent,
    LtiSettingsComponent,
    TurnitinWarningComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [HttpClient, CookieService, UserService, AppConfigService, ConfigurationHelperService, AuthGuard,
    { provide: APP_INITIALIZER, useFactory: initializeAppConfig, deps: [AppConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IAppConfig } from '../models/app-config';


@Injectable()
export class AppConfigService {

    static settings: IAppConfig;

    constructor(private http: HttpClient) {       
    }

    public load() {      
        
        if (AppConfigService.settings != null) return;

        const promise = this.getJSON().toPromise();

        promise.then(data => {
            AppConfigService.settings = <IAppConfig>data;
        });

        return promise;
    }

    public getJSON(): Observable<any> {

        // octopus will take care of changing config for each environment
        const jsonFile = `/config/config.json`;

        return this.http.get(jsonFile);
    }
}

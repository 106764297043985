<ul *ngIf="showContent" class="list-unstyled" >
    <li class="list-group-item">
      <div class="row">                  
        <div class="col-8 col-sm-10">
            Display TurnItIn warning and alternative exam script message to students?
        </div>
        <div class="col-4 col-sm-2" style="text-align: right;">
          <label class="switch">
            <input type="checkbox" [checked]="!ltiSettings.isTurnitinAvailable" (change)="ltiSettings.isTurnitinAvailable = !ltiSettings.isTurnitinAvailable">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 text-right">
        <div class="btn btn-secondary wrapButtonText mb-2" (click)="cancel()">Cancel</div>
        <div class="btn btn-primary wrapButtonText mb-2 ml-3" (click)="save()">Save</div>
        </div>
    </div>
    </li>
  </ul>
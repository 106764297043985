export class BaseObject implements IBaseObject {
    uri: string;
    version: string;
    courseCode: string;
}

export interface IBaseObject {
    uri: string;
    version: string;
    courseCode: string;
}
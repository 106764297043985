import { Injectable } from '@angular/core';
import { ExaminationEntry } from '@app/models/examination-entry';

@Injectable({
    providedIn: 'root'
})
export class TurnitinStateService {
    hasAgreedToOwnershipStatement: boolean;
    currentExaminationEntry: ExaminationEntry;
    constructor(){

    }

    setHasAgreedToOwnershipStatement(examinationEntry: ExaminationEntry): void{
        this.hasAgreedToOwnershipStatement = true;
        this.currentExaminationEntry = examinationEntry;
    }

    resetOwneshipStatementAgreement(): void{
        this.hasAgreedToOwnershipStatement = false;
        this.currentExaminationEntry = null;
    }
}
<div class="main-content">  
    <div class="scrollable full-height-minus-header">
      <div class="container px-md-0">
        
        <h1 *ngIf="isLoading" class="my-3">Submit your answers</h1>
        <h1 *ngIf="!isLoading" class="my-3">Submit your answers - {{courseName}}</h1>
      
        <hw-spinner class="px-3 py-1" [spinnerMessage]="'loading...'" [faColour]="'#8031A7'"
        [boxColour]="'#8031A7'" *ngIf="isLoading" [boxPadding]="'5px 15px'"></hw-spinner>
     
        <div class="card" >
          <div class="card-body">
            <div *ngIf="isIE()" role="alert" class="alert alert-danger"><i class="fa fa-lg fa-exclamation-triangle fa-fw"></i>Final Online assessments are not supported by your browser. Please upgrade to the latest version of Chrome, Safari, Edge or Firefox. (You may be able to download the assessment paper, but will not be able to submit your answers. For further information please contact <a href="mailto:ebs.enquiries@hw.ac.uk">ebs.enquiries@hw.ac.uk</a>.)</div>
            
            <hw-turnitin-warning [studentId]="getStudentId()" [courseName]="courseName" [courseCode]="courseCode" ></hw-turnitin-warning>

            <ng-container *ngIf="!isLoading && isValidPageRequest">

              <iframe [src]="turinitinFormUri | domSanitizerPipe: 'resourceUrl'" width="100%" height="480" frameborder="0"
                webkitallowfullscreen mozallowfullscreen allowfullscreen>
              </iframe>

              <div *ngIf="isInStudentRole() === true" class="alert alert-info" role="info">
                <h4>
                  <i class="fas fa-fw fa-exclamation-circle fa-lg"></i> Please note
                </h4>
                <p class="d-none d-md-block">
                  You should ensure that your file is named as follows {{getExamPaperFileName()}}
                </p>
                <p class="d-none d-md-block">
                  Once you have uploaded and reviewed your answers, you must press 'Submit to Turnitin'. You will then see your paper
                  listed with icon links to re-submit and download your paper, similar to this:
                  <img src="/assets/img/TurnitinScreenshotAfterUpload.jpg" alt="Turnitin screen after successful upload"
                    class="img img-responsive pt-2 pb-2 d-block" /><strong>If you see your paper listed like this, you have successfully 
                      submitted your answers.</strong></p>
                <p class="d-md-none">
                  Once you have uploaded and reviewed your answers, you must press 'Submit to Turnitin'. You will then see your paper
                  listed with icon links to re-submit and download your paper.</p>
                <p>
                  You can normally download a digital receipt that confirms that Turnitin received your paper. (You will see an extra icon next to the download option.)
                  However, please note that this option will not appear at busy times, so please do not worry if it is not available. 
                  You will not receive an email confirmation.
                </p>
                <p>
                  If you re-submit your answer, only the last upload file will be saved. Any previous upload for the assessment will be overwritten. If you receive a warning about a re-submission generating a new similarity report, please ignore this.
                </p>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 text-right">
                  <div  class="btn btn-secondary wrapButtonText ng-tns-c9-3 ng-star-inserted" (click)="navigateToExamList()" > Return to list of assessments </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>      
      </div>
    </div>
  </div>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { User, UserProfile, PersonalDetails } from '../models/user';
import { ServiceBase } from '../../core/service-base';
import { OperationResponse } from '../../common/models/operation-response';
import { CookieService } from '../cookies/cookie.service';
import { StaticText } from '../static-text';
import { ConfigurationHelperService } from '../app-configuration/configuration-helper.service';


@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    constructor(
        http: HttpClient,
        private cookieService: CookieService,
        toastr: ToastrService,
        configurationService: ConfigurationHelperService) {
        super(http, toastr, configurationService);
    }

    public currentUser: UserProfile = null;
    public userEnvironmentUri = StaticText.currentUserNoSlash;

  

    public getCurrentUser(): Observable<UserProfile> {
        const getCurrentUserObs = new Observable<UserProfile>(subscriber => {
                this.getCurrentUserProfile().subscribe(y => {
                    subscriber.next(y);
                    subscriber.complete();
            }, error => {
                this.getCurrentUserProfile().subscribe(y => {
                    subscriber.next(y);
                    subscriber.complete();
                }, err => {
                    subscriber.error(err);
                    subscriber.complete();
                });
            });
        });

        return getCurrentUserObs;
    }


    protected getCurrentUserProfile(): Observable<UserProfile> {
        const currentUserObs = new Observable<UserProfile>(subscribe => {
                if(this.currentUser != null && this.currentUser.authenticationDetails != null
                    && this.currentUser.authenticationDetails.isLoggedOut === false){
                        subscribe.next(this.currentUser);
                        subscribe.complete();
                    } else {
                        this.getCurrentUserProfileWhenOnline(subscribe);
                    }
        });

        return currentUserObs;
    }

    protected getCurrentUserProfileWhenOnline(subscriber: Subscriber<UserProfile>): void {
        // M.S. even if server fails, there sill be no item with this URI stored locally
        this.getObject<UserProfile>('authenticate/' + this.userEnvironmentUri)
            .subscribe(x => {
                if (x && x.operationSucceeded === true && x.data) {
                    if (this.currentUser == null) {
                        this.currentUser = x.data;
                    } else {
                        Object.assign(this.currentUser, x.data);
                    }

                    subscriber.next(x.data);
                } else {
                    this.currentUser = null;
                    subscriber.error();
                }
                subscriber.complete();
            }, error => {
                this.currentUser = null;
                subscriber.error(error);
            });
    }





    protected setRetrievedUser(userRecord: UserProfile): UserProfile {
        if (userRecord == null) { return userRecord; }

        if (userRecord.userAccount !== null) {
            userRecord.userAccount.isWorkingOffline = false;
        }

        userRecord.uri = this.userEnvironmentUri;

        // M.S. - Added this line for the non offline version (temporary code)
        this.currentUser = userRecord;

        return userRecord;
    }




    protected logOutRetrievedUser(userRecord: UserProfile): UserProfile {

        if (userRecord == null || userRecord.userAccount == null || userRecord.authenticationDetails == null) { return userRecord; }

        userRecord.uri = this.userEnvironmentUri;

        userRecord.userAccount.isWorkingOffline = false;
        userRecord.authenticationDetails.isAuthenticated = false;
        userRecord.authenticationDetails.isLoggedOut = true;

        // M.S. - Added this line for the non offline version (temporary code)
        this.currentUser = userRecord;

        return userRecord;
    }

    public resetUser(): void {
        this.currentUser = null;
    }

    // M.S. needs adjusted
    public async getCurrentUserAwaitable() {

        if (this.currentUser == null
            || this.currentUser.userAccount == null
            || this.currentUser.userAccount.username == null
            || this.currentUser.userAccount.username === '') {

            const result = await this.getObjectFromRemoteStoreSync<UserProfile>('authenticate/' + this.userEnvironmentUri);

            if (result.operationSucceeded && result.data != null) {
                this.currentUser = result.data;
            }
        }

        return this.currentUser;
    }

  
    public currentUserIsAuthenticatedOnRemoteServer(): boolean {

        // console.log('Checking authentication');
        const authenticationExpiryAsString = this.cookieService.get('AssessmentPlatform_AuthenticationExpiryTime');
        // console.log('Expires UTC:',cookieTimeoutUtc)

        if (authenticationExpiryAsString === null || authenticationExpiryAsString === undefined) {
            return false;
        }
        try {
            const cookieTimeoutUtc = new Date(authenticationExpiryAsString).toISOString();
            const dateNowUtc = new Date().toISOString();
            return cookieTimeoutUtc > dateNowUtc;
        } catch {
            return false;
        }
    }

    getDisplayName(personalDetails: PersonalDetails): string {
        if (personalDetails == null) { return ''; }
        return personalDetails.preferredName + ' ' + personalDetails.familyName;
    }
}

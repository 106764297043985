import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { ExaminationEntry } from '@app/models/examination-entry';
import { ExaminationTimetableEntry } from '@app/models/examination-timetable-entry';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';


@Injectable({
    providedIn: 'root'
})
export class ExamsService {
    constructor(private serviceBase: ServiceBase){

    }

    upcomingExamsUrl(): string{
        return 'exams/studentupcoming';
    }

    getUpcomingExams(): Observable<OperationResponse<ExaminationEntry[]>>{
        const upcomingStudentExamsUrl = this.upcomingExamsUrl();
        return this.serviceBase.getObjects<ExaminationEntry>(upcomingStudentExamsUrl);
    }

    getExamsForFaculty(): Observable<OperationResponse<ExaminationTimetableEntry[]>>{
        const examTimetableUrl = "exams/currentexaminations";
        return this.serviceBase.getObjects<ExaminationTimetableEntry>(examTimetableUrl);
    }

    examPaperUrl(exam: ExaminationEntry): string{
        if (exam == null) { return ''; }

        const examPaperUrl = this.serviceBase.getApiServerUri() + `exams/exampaper/${exam.sessionCode}/${exam.courseCode}/${exam.languageCode}`;

        return examPaperUrl;
    }
}
import { Component, Input, OnInit } from '@angular/core';
import { TurnitinLtiService } from '@app/services/turnitin-lti.service';

@Component({
  selector: 'hw-turnitin-warning',
  templateUrl: './turnitin-warning.component.html',
  styleUrls: ['./turnitin-warning.component.scss']
})
export class TurnitinWarningComponent implements OnInit {

  @Input()
  studentId: string;

  @Input()
  courseName: string;

  @Input()
  courseCode: string;

  showTurnitinWarning: boolean;

  constructor(private turnitinSerice: TurnitinLtiService) { }

  ngOnInit(): void {
    this.showTurnitinWarning = false;

    this.turnitinSerice.getLtiStatus().subscribe(x =>{
      if (x.operationSucceeded && x.data && x.data.isTurnitinAvailable === false){
        this.showTurnitinWarning = true;
      }
    }, err => {});
  }

  getPaperName(): string{
    return `${this.studentId}_${this.courseName}_FOA`;
  }

  getMailToLink(): string{
    return `mailto:examscripts@hw.ac.uk?subject=${this.getPaperName()}`;
  }
}
